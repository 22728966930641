import { Component, OnInit, Inject } from "@angular/core";
import { TokenStorageService } from "../_services/token-storage.service";
import { AuthService } from "../_services/auth.service";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.less"],
})
export class NavbarComponent implements OnInit {
  private roles: any[] = [];
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  username?: string;
  isAdmin = false;
  isEdit = false;
  isMember = false;
  isPhoto = false;
  isCatal = false;
  isSBFm = false;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private authService: AuthService,
    private tokenStorageService: TokenStorageService
  ) {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.roles = user.roller;

      this.username = user.username;

      this.isAdmin = user.roller.isAdmin;
      this.isEdit = user.roller.isEdit;
      this.isMember = user.roller.isMember;
      this.isPhoto = user.roller.isPhoto;
      this.isCatal = user.roller.isCatal;
      this.isSBFm = user.roller.isSBFm;
    }
  }

  ngOnInit() {}

  logout(): void {
    this.tokenStorageService.signOut();
    window.location.reload();
    top.location.href = "/atlas";
  }
}
