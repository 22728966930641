import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PopupService {
  constructor() {}

  makePopup(feature: any): string {
    return (
      `` +
      "ID: " +
      feature.id +
      "<br>Provins:" +
      feature.properties.province +
      "<br>" +
      "Bristkategori:" +
      feature.properties.Bristkategori +
      "<br>" +
      "Artantal:" +
      feature.properties.artantal +
      "<br>" +
      "Krav:" +
      feature.properties.krav
    );
  }
}
