<div
  *ngIf="!isLoggedIn"
  class="login-main"
  id="login"
  style="display: flex;flex-direction: column;background-color: white;color:black;justify-content: center;align-items: center;"
>
  <div style="width:700px;color:black">
    <img
      mat-card-image
      src="../../assets/images/login_header_image_big.png"
      class="login-header-image-big"
    />
    
            <br><br><b>Inloggning för att administrera Atlasbokningen</b>
            <form name="form" #f="ngForm" novalidate>
                <mat-form-field style="width:500px;">
                  <mat-label>E-postadress</mat-label>
                  <input
                    matInput
                    placeholder="E-postadress"
                    name="email"
                    [(ngModel)]="form.email"
                    required
                    #email="ngModel"
                    
                  />
                </mat-form-field>
                <div
                  class="alert alert-danger"
                  role="alert"
                  *ngIf="email.errors && f.submitted"
                >
                  <br>E-post adressen måste anges!
                </div><br>
                <mat-form-field style="width:500px;">
                  <mat-label>Lösenord</mat-label>
                  <input
                    [type]="hide ? 'password' : 'text'"
                    matInput
                    placeholder="Lösenord"
                    name="password"
                    [(ngModel)]="form.password"
                    required
                    minlength="6"
                    #password="ngModel"
                  />
                  <button
                    mat-icon-button
                    matSuffix
                    (click)="hide = !hide"
                    [attr.aria-label]="'Dölj lösenordet'"
                    [attr.aria-pressed]="hide"
                  >
                    <mat-icon>{{
                      hide ? "visibility_off" : "visibility"
                    }}</mat-icon>
                  </button>
                </mat-form-field>
                <div
                  class="alert alert-danger"
                  role="alert"
                  *ngIf="password.errors && f.submitted"
                >
                  <div *ngIf="password.errors.required">
                    <br>Lösenord måste anges
                  </div>
                  <div *ngIf="password.errors.minlength">
                    <br>Lösenordet måste ha minst 6 tecken
                  </div>
                </div>
                <div
                  class="alert alert-danger"
                  role="alert"
                  *ngIf="isLoginFailed"
                >
                  <br>Inloggningen misslyckades, {{ errorMessage }}, försök igen
                </div>
            </form>
              <button
                mat-raised-button
                color="primary"
                (click)="f.form.valid && onSubmit()"
              >
                Logga in
              </button>
  </div>
</div>
