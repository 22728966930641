<div style="display: flex;flex-direction: row;">
  <div style="position: relative;width:40%">
    <div
      class="map"
      style="height: 100vh; width: 100%"
      id="map"
      leaflet
      [leafletOptions]="options"
      [leafletLayers]="markers"
      (leafletMapReady)="onMapReady($event)"
    ></div>
    <div
      style="
        position: absolute;
        left: 40%;
        top: 0px;
        z-index: 1003;
        font-size: xx-small;
      "
    >
      version {{ version }}
    </div>
    <div
      style="position: absolute; left: 50px; top: 10px; z-index: 1003"
      *ngIf="!isLoggedIn"
    >
      <button
        mat-mini-fab
        color="primary"
        aria-label="Logga in"
        routerLink="../login"
        style="background-color: #277e32; color: #fff; margin: 2px"
      >
        <mat-icon>perm_identity</mat-icon>
      </button>
    </div>
    <div
      style="position: absolute; left: 95px; top: 10px; z-index: 1003"
      *ngIf="isLoggedIn"
    >
      <button
        mat-mini-fab
        color="primary"
        aria-label="Logga ut"
        (click)="logout()"
        style="background-color: #277e32; color: #fff; margin: 2px"
      >
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </div>
    <div
      style="position: absolute; left: 50px; top: 10px; z-index: 1003"
      *ngIf="isLoggedIn"
    >
      <button
        mat-mini-fab
        color="primary"
        aria-label="Till administrationen"
        routerLink="../bokningar"
        style="background-color: #277e32; color: #fff; margin: 2px"
      >
        <mat-icon>description</mat-icon>
      </button>
    </div>
    <div class="atlasLegend">
      <div>
        <div style="display: flex;flex-direction: row;">
          <div
            fxFlex="15px"
            class="atlasLegendColor"
            style="background-color: white"
          ></div>
          <div fxFlex class="atlasLegendText">Liten landarea</div>
        </div>
        <div style="display: flex;flex-direction: row;">
          <div
            fxFlex="15px"
            class="atlasLegendColor"
            style="background-color: rgb(159, 32, 231)"
          ></div>
          <div fxFlex class="atlasLegendText">0 - 50%</div>
        </div>
        <div style="display: flex;flex-direction: row;">
          <div
            fxFlex="15px"
            class="atlasLegendColor"
            style="background-color: rgb(255, 154, 41)"
          ></div>
          <div fxFlex class="atlasLegendText">50% - 90%</div>
        </div>
        <div style="display: flex;flex-direction: row;">
          <div
            fxFlex="15px"
            class="atlasLegendColor"
            style="background-color: rgb(254, 254, 61)"
          ></div>
          <div fxFlex class="atlasLegendText">90% - 100%</div>
        </div>
        <div style="display: flex;flex-direction: row;">
          <div
            fxFlex="15px"
            class="atlasLegendColor"
            style="background-color: rgb(205, 205, 205)"
          ></div>
          <div fxFlex class="atlasLegendText">Inventerad</div>
        </div>
      </div>
    </div>
  </div>
  <div style="color: white;width:60%">
    <div style="position: relative;">
      <div style="position: relative;display: flex;flex-direction: row;height:60px;margin:10px;">
        <div>
            <div style="font-size:x-large">Boka inventeringsruta i Atlasprojektet</div>
            Klicka på kartan för att välja ruta
          </div>
          <div>
          <img
            src="../../assets/images/sbflogga.jpg"
            style="position: absolute; right: 0px; height: 60px"
          />
        </div>
      </div>
      <div *ngIf="!showSmallMap && !bookingDone" style="margin:10px">
        <h1>Atlasprojektet</h1>
        <img
          src="../../assets/images/salix-myrtilloides-odonvide.jpg"
          style="max-height: 300px"
        />
        <p
          style="
            max-width: 500px;
            padding-top: 20px;
            font-family: 'Courier New', monospace;
          "
        >
          Svenska botaniska föreningens Atlasprojekt jobbar med att ta fram en
          ny Svensk flora atlas för kärlväxter. För att det skall vara möjligt
          behövs kunskapen om växtutbredningarna i Sverige förbättras. Atlasen
          kommer att redovisa utbredningarna inom 10 km rutor och den här kartan
          visar vilka områden i Sverige där kunskapsläget för förekomster av
          kärlväxter är uppenbart bristfälligt. Du kan hjälpa till genom att
          inventera dessa rutor! Boka den eller de rutor du vill inventera under
          året.
        </p>
      </div>
      <div *ngIf="bookingDone" style="margin:10px">
        <h1>Tack för att du bokat rutan!</h1>
        <p
          style="
            max-width: 500px;
            padding-top: 20px;
            font-family: 'Courier New', monospace;
          "
        >
          En 10 km är stor och det är bara möjligt att besöka delar av rutan.
          Planera in ett antal lokaler som skall besökas i rutan. Försök att
          inkludera alla rutans olika växtmiljöer; skog, myr, kulturmark,
          stränder, hällmarker, branter etc. För varje lokal gör du en
          fullständig lista över de kärlväxter du hittar. Glöm ej att notera
          koordinat och en kortare lokalbeskrivning. Fynduppgifter skall
          rapporteras så snart som möjligt till Artportalen. Om du inser att du
          inte kommer att hinna med att inventera ruta under innevarande år så
          avboka rutan. [Så behövs en kontaktuppgift på sbf]
        </p>
        <p>Ladda ner karta</p>
      </div>
      <div *ngIf="showSmallMap" style="margin:10px">
        <div fxLayout="column" fxLayoutAlign="top top">
          <div
            style="display: flex;flex-direction: row;"
          >
            <div style="padding: 5px;width:40%">
              <div>
                  <div
                    style="height: 300px; width: 100%"
                    id="map2"
                    leaflet
                    [leafletOptions]="options2"
                    [leafletLayers]="markers2"
                    (leafletMapReady)="onMapReady2($event)"
                  ></div>
              </div>
            </div>
            <div style="padding: 5px;width:60%">
              <div style="background-color: antiquewhite; position: relative;color:black">
                <div style="display: flex;flex-direction: row;align-items: center;">
                  <mat-icon style="color:black">info</mat-icon>
                  <div style="font-size:x-large">Information</div>
                </div>
                <div style="margin:10px;">
                  <div style="margin-bottom: 40px">
                    ID:<br />
                    <h3>{{ bokaRutaInfo["id_grid"] }}</h3>
                    <b>Status: {{ bokaRutaInfo["Bristkategori"] }}</b
                    ><br />

                    Antal noterade arter: {{ bokaRutaInfo["artantal"] }}<br />
                    Mål antal arter: {{ bokaRutaInfo["krav"] }}<br />
                  </div>                  
                </div>
                <div *ngIf="bokningsbar" class="InfoFooter">
                  Boka rutan i formuläret nedan
                </div>
                <div *ngIf="!bokningsbar" class="InfoFooter">
                  Inte bokningsbar
                </div>
              </div>
            </div>
          </div>
          <div style="padding: 5px;display: flex;flex-direction: column;" *ngIf="bokningsbar">            
              <div>
                
                  <div style="font-size:x-large"> <mat-icon mat-card-avatar>assignment</mat-icon>Bokningsformulär för ruta {{ bokaRutaInfo["id_grid"] }}</div>
                  Fyll i formuläret nedan för att boka rutan
              </div>
              <div style="display: flex;flex:1;background-color: #fff;padding:10px;border-radius: 15px;flex-direction: column;">
                <form [formGroup]="addBookingGrp">
                  <mat-form-field appearance="outline" style="width:100%;font-size: small;">
                    <mat-label>Ert namn</mat-label>
                    <input
                      matInput
                      placeholder="Namn"
                      name="namn"
                      required
                      minlength="6"
                      formControlName="namn"
                      
                    />
                  </mat-form-field><br>
                  <mat-form-field appearance="outline" style="width:100%;font-size: small;">
                    <mat-label>E-postadress</mat-label>
                    <input
                      matInput
                      placeholder="E-postadress"
                      name="epost"
                      required
                      minlength="6"
                      formControlName="epost"
                    />
                  </mat-form-field><br>
                  <mat-form-field appearance="outline" style="width:100%;font-size: small;">
                    <mat-label>Mobiltelefonnummer</mat-label>
                    <input
                      matInput
                      placeholder="Mobiltelefonnummer"
                      name="mobil"
                      required
                      minlength="6"
                      formControlName="mobil"
                    />
                  </mat-form-field><br>
                  <mat-form-field appearance="outline" style="width:100%;font-size: small;">
                    <mat-label>Kommentar</mat-label>
                    <input
                      matInput
                      placeholder="Kommentar"
                      name="kommentar"
                      formControlName="kommentar"
                    />
                  </mat-form-field>
                </form>
                <br>
                <button
                  mat-raised-button
                  color="primary"
                  (click)="sparaBokning()"
                  [disabled]="!addBookingGrp.valid"
                >
                  Boka
                </button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
