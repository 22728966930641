import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const AUTH_API = 'https://api.botanikportalen.se/api/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) { }

  login(email: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'login.php', {
      email,
      password
    }, httpOptions);
  }

  newpass(email: string): Observable<any> {
    return this.http.post(AUTH_API + 'newpass.php', {
      email
    }, httpOptions);
  }
  passreset(token: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'resetpassword.php', {
      token,
      password
    }, httpOptions);
  }

  register(username: string, email: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'register.php', {
      username,
      email,
      password
    }, httpOptions);
  }

  access(): Observable<any> {
    return this.http.post(AUTH_API + 'protected.php', httpOptions);
  }
}
