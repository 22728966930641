import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

//Components
import { AtlasComponent } from "./atlas/atlas.component";
import { BokningarComponent } from "./bokningar/bokningar.component";
import { LoginComponent } from "./login/login.component";

const routes: Routes = [
  { path: "", redirectTo: "/atlas", pathMatch: "full" },
  { path: "atlas", component: AtlasComponent },
  { path: "login", component: LoginComponent },
  { path: "bokningar", component: BokningarComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
