import { Component, OnInit } from "@angular/core";
import { AuthService } from "../_services/auth.service";
import { TokenStorageService } from "../_services/token-storage.service";
import { Router } from "@angular/router";
import { UserapiService } from "../services/userapi.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  form: any = {
    username: null,
    password: null,
  };
  isLoggedIn = false;
  isLoginFailed = false;
  isNewpasssent = false;
  isNewpasserror = false;
  errorMessage = "";
  newpassMessage = "";
  newpasserrorMessage = "";
  roles: string[] = [];
  hide = true;

  form2: any = {
    firstname: null,
    lastname: null,
    email: null,
    password: null,
  };
  isSuccessful = false;
  isSignUpFailed = false;
  errorMessageRegister = "";

  constructor(
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private UserapiService: UserapiService
  ) {}

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;

      this.goAdmin();
    }
  }

  newpass(): void {
    const { emailrelease } = this.form;

    this.authService.newpass(emailrelease).subscribe((data) => {
      if (data.success) {
        this.isNewpasssent = true;
        this.isNewpasserror = false;
        this.newpassMessage = data.message;
      } else {
        this.newpasserrorMessage = data.errormessage;
        this.isNewpasserror = true;
        this.isNewpasssent = false;
      }
    });
  }
  onSubmit(): void {
    const { email, password } = this.form;

    this.authService.login(email, password).subscribe((data) => {
      if (data.success) {
        this.tokenStorage.saveToken(data.jwt);
        this.tokenStorage.saveUser(data);

        this.isLoginFailed = false;
        this.isLoggedIn = true;
        const user = this.tokenStorage.getUser();
        this.roles = this.tokenStorage.getUser().roles;
        this.UserapiService.log(
          user.id,
          0,
          0,
          "Loggade in på Atlasbokningen"
        ).subscribe((data) => {
          this.reloadPage();
        });
      } else {
        this.errorMessage = data.message;
        this.isLoginFailed = true;
      }
    });
  }

  reloadPage(): void {
    top.location.href = "./atlas";
  }

  goAdmin(): void {
    top.location.href = "./bokningar";
  }
}
