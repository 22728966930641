import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserapiService {
  private REST_API_SERVER = "https://api.botanikportalen.se/api/users";

  headers : any;
  constructor(private httpClient: HttpClient) { 
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
  }

  public getAllUsers(){
    let params = new FormData();
    return this.httpClient.post(`${this.REST_API_SERVER}/getAllUsers`,params,this.headers);
  }

  public setRole(uid,roleid,varde) {
    let params = new FormData();
    params.append('uid',uid);
    params.append('roleid', roleid);
    params.append('varde', varde);
    return this.httpClient.post(`${this.REST_API_SERVER}/setRole`,params,this.headers);
  }

  public register(firstname: string,lastname: string, email: string, password: string): Observable<any> {
    let params = new FormData();
    params.append('firstname',firstname);
    params.append('lastname',lastname);
    params.append('email',email);
    params.append('password',password);
    return this.httpClient.post(`${this.REST_API_SERVER}/register`,params,this.headers);
  }

  public log(uid,taxonid,listid,vad) {
    let params = new FormData();
    params.append('uid',uid);
    params.append('taxonid',taxonid);
    params.append('listid',listid);
    params.append('vad',vad);
    return this.httpClient.post(`${this.REST_API_SERVER}/logga`,params,this.headers);
  }
}

