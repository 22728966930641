import { Component, OnInit, NgZone, ChangeDetectorRef } from "@angular/core";
import {
  featureGroup,
  latLng,
  tileLayer,
  Layer,
  marker,
  icon,
  MarkerOptions,
  polygon,
} from "leaflet";
import * as L from "leaflet";
import { AuthService } from "../_services/auth.service";
import { TokenStorageService } from "../_services/token-storage.service";
import { KartapiService } from "../services/kartapi.service";
import { UserapiService } from "../services/userapi.service";
import { ApapiService } from "../services/apapi.service";
import { GeolocationService } from "@ng-web-apis/geolocation";
import { range } from "rxjs";
import { map, filter, take } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { PopupService } from "../services/popup.service";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { FormBuilder, FormGroup, FormArray, FormControl } from "@angular/forms";
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
  selector: "app-atlas",
  templateUrl: "./atlas.component.html",
  styleUrls: ["./atlas.component.scss"],
})
export class AtlasComponent implements OnInit {

  OpenStreetMap = L.tileLayer(
    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    {
      attribution: "&copy; OpenStreetMap contributors",
    }
  );

  Esri_WorldImagery = L.tileLayer(
    "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    {
      attribution:
        "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
    }
  );

  OpenTopoMap = L.tileLayer(
    "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
    {
      maxZoom: 17,
      attribution:
        'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
    }
  );
  OpenStreetMap2 = L.tileLayer(
    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    {
      attribution: "&copy; OpenStreetMap contributors",
    }
  );

  Esri_WorldImagery2 = L.tileLayer(
    "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    {
      attribution:
        "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
    }
  );

  OpenTopoMap2 = L.tileLayer(
    "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
    {
      maxZoom: 17,
      attribution:
        'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
    }
  );

  Stadia_AlidadeSmooth = L.tileLayer(
    "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png",
    {
      maxZoom: 20,
      attribution:
        '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
    }
  );

  Stadia_AlidadeSmooth2 = L.tileLayer(
    "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png",
    {
      maxZoom: 20,
      attribution:
        '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
    }
  );

  baseMaps = {
    Grå: this.Stadia_AlidadeSmooth,
    Normal: this.OpenStreetMap,
    Satellit: this.Esri_WorldImagery,
    Topografisk: this.OpenTopoMap,
  };

  baseMaps2 = {
    Grå: this.Stadia_AlidadeSmooth2,
    Normal: this.OpenStreetMap2,
    Satellit: this.Esri_WorldImagery2,
    Topografisk: this.OpenTopoMap2,
  };

  currentLocation = marker([62.308, 15.77], {
    icon: icon({
      iconSize: [32, 32],
      iconAnchor: [16, 16],
      iconUrl: "../../assets/images/icon_bullet-red_2.png",
    }),
    title: "Din position",
  });

  options = {
    layers: [this.Stadia_AlidadeSmooth],
    zoom: 5,
    center: latLng([62.308, 15.77]),
  };
  options2 = {
    layers: [this.OpenStreetMap2],
    zoom: 5,
    center: latLng([62.308, 15.77]),
  };

  obscart: any[] = [];
  kartmarkers: any[] = [];
  antalobsar = 0;
  tracker = false;
  markers: Layer[] = [];
  markers2: Layer[] = [];
  ObsInfo: any[] = [];
  markerdata = true;
  showobsinfo = false;
  markertext = "";
  activeObsID = 0;
  public map: L.Map = null;
  public map2: L.Map = null;
  uid = 0;
  settings: any[] = [];
  isLoggedIn = false;

  form: any = {
    username: null,
    password: null,
  };
  isLoginFailed = false;
  errorMessage = "";
  roles: string[] = [];
  hide = true;
  infilterfritext = "";
  markerdivshow = 0;
  tooltipClickedTimes = 0;
  actobscart: any[] = [];
  obsinfo: any[];
  ObsMedia: any[];
  currLat = 0;
  currLng = 0;
  json;
  states = [];
  geojsonFeature = [];
  bokaRutaInfo: any[] = [];
  bokaRutaAllInfo: any[] = [];
  testVariable = 1;
  smallLayer = L.geoJSON();
  showSmallMap = false;
  bokningsbar = false;
  bokning: any = {
    ruta: null,
    namn: null,
    epost: null,
    mobil: null,
    kommentar: null,
  };
  addBookingGrp = this.fb.group({
    ruta: [""],
    namn: [""],
    epost: [""],
    mobil: [""],
    kommentar: [""],
    geojson: [""],
    rutdata: [""],
  });
  rutInfo: any[] = [];
  bookingDone = false;
  version = "230309.1100";

  constructor(
    private http: HttpClient,
    private zone: NgZone,
    private popupService: PopupService,
    private cdr: ChangeDetectorRef,
    private KartapiService: KartapiService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private tokenStorage: TokenStorageService
  ) {}

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
    }
  }

  fixCoord = function (coord) {
    var b = coord.map(function (x) {
      var couple = /\d+.\d+, \d+.\d+/g.exec(x).toString();
      var splitted = couple.split(", ");
      return "[" + splitted[1] + "," + splitted[0] + "]";
    });
    b.join();
    return b;
  };

  bokaRuta = function () {
    //Uppdatera lilla kartan så att den visa rutan inzoomad
    const geojsonFeature = this.bokaRutaAllInfo.target.feature;

    this.addBookingGrp.controls["ruta"].setValue(geojsonFeature.properties.id_grid);
    this.addBookingGrp.controls["geojson"].setValue(
      JSON.stringify(this.bokaRutaAllInfo.target._latlngs)
    );
    this.addBookingGrp.controls["rutdata"].setValue(
      JSON.stringify(this.bokaRutaAllInfo.target.feature)
    );

    this.smallLayer.clearLayers();
    this.smallLayer
      .addData(geojsonFeature, {
        onEachFeature: this.onEachFeatureSmall.bind(this),
      })
      .addTo(this.map2);

    this.smallLayer.setStyle(this.style(this.bokaRutaAllInfo.target.options));
    this.map2.fitBounds(this.smallLayer.getBounds());
    setTimeout(() => {
      this.map2.invalidateSize();
    }, 1000);

    this.rutInfo = [];
    this.KartapiService.getBooking(geojsonFeature.properties.id_grid).subscribe(
      (data) => {
        this.rutInfo = data as any;
        if (data != null) {
          this.bokningsbar = false;
        }
      }
    );

    if (
      geojsonFeature.properties.utfall == "Inventerad" ||
      geojsonFeature.properties.utfall == "Liten landyta" ||
      this.rutInfo.length != 0
    ) {
      this.bokningsbar = false;
    } else {
      this.bokningsbar = true;
    }
  };

  style(feature) {
    return {
      weight: feature.weight,
      opacity: feature.opacity,
      color: feature.color,
      fillOpacity: feature.fillOpacity,
      fillColor: feature.fillColor,
    };
  }

  onEachFeatureSmall(feature, layer): any {
    var self = this;
    if (feature.properties) {
      layer.bindTooltip(
        "ID: " +
          feature.properties.id_grid +
          "<br>" +
          "Bristkategori: " +
          feature.properties.Bristkategori +
          "<br>" +
          "Artantal: " +
          feature.properties.artantal +
          "arter" +
          "<br>" +
          "Krav: " +
          feature.properties.krav +
          "arter"
      );
    }
  }

  onEachFeature(feature, layer): any {
    var self = this;
    if (feature.properties) {
      layer.bindTooltip(
        "ID: " +
          feature.properties.id_grid +
          "<br>" +
          "Utfall: " +
          feature.properties.utfall +
          "<br>" +
          "Artantal: " +
          feature.properties.artantal +
          "arter" +
          "<br>" +
          "Krav: " +
          feature.properties.krav +
          "arter"
      );
      layer.on("click", <LeafletMouseEvent>(e) => {
        this.zone.run(() => {
          this.testVariable++;
          this.showSmallMap = true;
          this.bookingDone = false;
          this.bokaRutaInfo = feature.properties;
          this.bokaRutaAllInfo = e;
          this.cdr.detectChanges();
          this.bokaRuta();
        });
      });
    }
  }

  onMapReady2(map2: L.Map) {
    var self = this;
    setTimeout(() => {
      map2.invalidateSize();
    }, 0);
    this.map2 = map2;

    L.control.layers(this.baseMaps2).addTo(map2);
  }

  onMapReady(map: L.Map) {
    var self = this;
    setTimeout(() => {
      map.invalidateSize();
    }, 0);
    this.map = map;

    L.control.layers(this.baseMaps).addTo(map);

    this.http
      .get("../../assets/Bristkarta_20230215.geojson")
      .subscribe((geoJson: any) => {
        const geojsonFeature = geoJson;

        L.geoJSON(geojsonFeature, {
          style: function (feature) {
            switch (feature.properties.utfall) {
              case "Inventerad":
                return {
                  weight: 0.3,
                  opacity: 1,
                  color: "white",
                  fillOpacity: 0.5,
                  fillColor: "rgb(205,205,205)",
                };
              case "0% - 50%":
                return {
                  weight: 0.3,
                  opacity: 1,
                  color: "white",
                  fillOpacity: 0.5,
                  fillColor: "rgb(159,32,231)",
                };
              case "50% - 90%":
                return {
                  weight: 0.3,
                  opacity: 1,
                  color: "white",
                  fillOpacity: 0.5,
                  fillColor: "rgb(255,154,41)",
                };
              case "90% - 100%":
                return {
                  weight: 0.3,
                  opacity: 1,
                  color: "white",
                  fillOpacity: 0.5,
                  fillColor: "rgb(254,254,61)",
                };
              case "Liten landarea":
                return {
                  weight: 0.3,
                  opacity: 1,
                  color: "white",
                  fillOpacity: 0.5,
                  fillColor: "white",
                };
            }
          },
          onEachFeature: this.onEachFeature.bind(this),
        }).addTo(map);
      });
  }

  sparaBokning(): any {
    this.KartapiService.addBooking(
      this.addBookingGrp.get("ruta").value,
      this.addBookingGrp.get("namn").value,
      this.addBookingGrp.get("epost").value,
      this.addBookingGrp.get("mobil").value,
      this.addBookingGrp.get("kommentar").value,
      this.addBookingGrp.get("geojson").value,
      this.addBookingGrp.get("rutdata").value
    ).subscribe(
      (data) => {
        this._snackBar.open("Bokningen är sparad", "", {
          duration: 2000,
        });
        this.showSmallMap = false;
        this.bokningsbar = false;
        this.bokning = {
          ruta: null,
          namn: null,
          epost: null,
          mobil: null,
          kommentar: null,
        };
        this.addBookingGrp = this.fb.group({
          ruta: [""],
          namn: [""],
          epost: [""],
          mobil: [""],
          kommentar: [""],
          geojson: [""],
          rutdata: [""],
        });
        this.rutInfo = [];
        this.bookingDone = true;
      },
      (error) => {
        this._snackBar.open("Problem att spara bokningen, försök igen", "", {
          duration: 2000,
        });
      }
    );
  }

  logout(): void {
    this.tokenStorage.signOut();
    top.location.href = "/atlas";
  }
}
