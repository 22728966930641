import { Component, OnInit, NgZone, ChangeDetectorRef } from "@angular/core";
import { TokenStorageService } from "../_services/token-storage.service";
import { Router } from "@angular/router";
import {
  featureGroup,
  latLng,
  tileLayer,
  Layer,
  marker,
  icon,
  MarkerOptions,
  polygon,
} from "leaflet";
import * as L from "leaflet";
import { HttpClient } from "@angular/common/http";
import { KartapiService } from "../services/kartapi.service";
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
  selector: "app-bokningar",
  templateUrl: "./bokningar.component.html",
  styleUrls: ["./bokningar.component.scss"],
})
export class BokningarComponent implements OnInit {
  OpenStreetMap = L.tileLayer(
    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    {
      attribution: "&copy; OpenStreetMap contributors",
    }
  );

  Esri_WorldImagery = L.tileLayer(
    "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    {
      attribution:
        "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
    }
  );

  OpenTopoMap = L.tileLayer(
    "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
    {
      maxZoom: 17,
      attribution:
        'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
    }
  );
  
  Stadia_AlidadeSmooth = L.tileLayer(
    "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png",
    {
      maxZoom: 20,
      attribution:
        '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
    }
  );

  baseMaps = {
    Grå: this.Stadia_AlidadeSmooth,
    Normal: this.OpenStreetMap,
    Satellit: this.Esri_WorldImagery,
    Topografisk: this.OpenTopoMap,
  };

  options = {
    layers: [this.Stadia_AlidadeSmooth],
    zoom: 5,
    center: latLng([62.308, 15.77]),
  };
  isLoggedIn = false;
  roles: string[] = [];
  markers: Layer[] = [];
  public map: L.Map = null;
  testVariable = 1;
  geojsonFeature;
  bokaRutaInfo;
  bokaRutaAllInfo: any[] = [];
  allBookings: any[] = [];
  geojsonData;
  metadata;
  polygon;

  constructor(
    private tokenStorage: TokenStorageService,
    private router: Router,
    private http: HttpClient,
    private zone: NgZone,
    private cdr: ChangeDetectorRef,
    private KartapiService: KartapiService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
    }

    this.KartapiService.getBookings().subscribe((data) => {
      this.allBookings = data as any;
    });
  }

  drawMap() {
    this.KartapiService.getBookings().subscribe((data: any) => {
      this.geojsonData = data;
      var rc = "rgb(255,154,41)";
      this.geojsonData.forEach((f, j) => {
        if (this.geojsonData[j].verifierad == 0) {
          rc = "rgb(159, 32, 231)";
        } else {
          rc = "rgb(255,154,41)";
        }
        this.polygon = L.polygon(JSON.parse(this.geojsonData[j].geojson), {
          weight: 0.3,
          opacity: 1,
          color: "red",
          fillOpacity: 0.5,
          fillColor: rc,
        })
          .bindTooltip(
            "<b>Ruta:</b> " +
              this.geojsonData[j].ruta +
              "<br>" +
              "<b>Bokad:</b> " +
              this.geojsonData[j].added +
              "<br>" +
              "<b>Bokad av:</b><br>" +
              this.geojsonData[j].namn +
              "<br>" +
              this.geojsonData[j].epost +
              "<br>" +
              this.geojsonData[j].mobil +
              "<br><b>Kommentar från bokaren:</b><br>" +
              this.geojsonData[j].kommentar +
              "<br><br><b>Verifierad:</b> " +
              this.geojsonData[j].verifierad
          )
          .on("click", <LeafletMouseEvent>(e) => {
            this.zone.run(() => {
              this.testVariable++;
              this.bokaRutaInfo = JSON.stringify(this.geojsonData[j]);
              this.bokaRutaInfo = JSON.parse(this.bokaRutaInfo);
              this.bokaRutaAllInfo = e;
              this.metadata = JSON.parse(this.bokaRutaInfo["rutdata"]);
              this.cdr.detectChanges();
            });
          })
          .addTo(this.map);
      });
    });
  }

  onMapReady(map: L.Map) {
    var self = this;
    setTimeout(() => {
      map.invalidateSize();
    }, 0);
    this.map = map;

    L.control.layers(this.baseMaps).addTo(map);

    this.drawMap();
  }

  delBokning = function (): void {
    if (confirm("Vill ni verkligen radera denna bokning?")) {
      var ruta = this.bokaRutaInfo["ruta"];
      this.KartapiService.delBooking(ruta).subscribe((data: any) => {
        let snackBarRef = this._snackBar.open(data["message"], "", {
          duration: 2000,
          panelClass: ["success-snackbar"],
        });
        top.location.href = "/bokningar";
      });
    }
  };

  verBokning = function (ver): void {
    var txt = "";
    ver == 0
      ? (txt = "Vill ni ta bort verifieringen på denna ruta?")
      : (txt = "Vill ni verifiera på denna ruta?");
    if (confirm(txt)) {
      var ruta = this.bokaRutaInfo["ruta"];
      this.KartapiService.verBooking(ruta, ver).subscribe((data: any) => {
        let snackBarRef = this._snackBar.open(data["message"], "", {
          duration: 2000,
          panelClass: ["success-snackbar"],
        });
        this.bokaRutaInfo["verifierad"] = ver;
        this.drawMap();
      });
    }
  };
}
