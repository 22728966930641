import { Component, Inject, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { TokenStorageService } from "./_services/token-storage.service";
import { SwUpdate } from "@angular/service-worker";
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less"],
})
export class AppComponent implements OnInit {
  title = "Atlasprojektet";
  sitemap = ["fas", "sitemap"];
  durationInSeconds = 3;

  private roles: string[] = [];
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  username?: string;
  isAdmin = false;

  constructor(
    private tokenStorageService: TokenStorageService,
    @Inject(DOCUMENT) private doc: Document,
    library: FaIconLibrary,
    private swUpdate: SwUpdate,
    public router: Router
  ) {

    library.addIconPacks(fas);

    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.roles = user.roles;

      this.username = user.username;

      this.isAdmin = this.tokenStorageService.getRole(1);
    }
  }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm("En ny version finns, vill ni ladda ner den?")) {
          window.location.reload();
        }
      });
    }
  }

  logout(): void {
    this.tokenStorageService.signOut();
    window.location.reload();
  }
}
