import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpHeaders,
} from "@angular/common/http";
import { TokenStorageService } from "../_services/token-storage.service";

@Injectable({
  providedIn: "root",
})
export class KartapiService {
  private REST_API_SERVER = "https://api.botanikportalen.se/api/karta";
  currentUser: any;
  headers: any;

  constructor(
    private httpClient: HttpClient,
    private token: TokenStorageService
  ) {
    this.currentUser = this.token.getUser();
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Authorization", "JWT " + this.currentUser.jwt);

    this.headers = new HttpHeaders().set(
      "Authorization",
      "JWT " + this.currentUser.jwt
    );
  }

  public getCart(uid) {
    let params = new FormData();
    params.append("uid", uid);
    return this.httpClient.post(
      `${this.REST_API_SERVER}/getCart`,
      params,
      this.headers
    );
  }

  public getCartById(uid, cartid) {
    let params = new FormData();
    params.append("uid", uid);
    params.append("cartid", cartid);
    return this.httpClient.post(
      `${this.REST_API_SERVER}/getCartById`,
      params,
      this.headers
    );
  }

  public getUserSettings(uid) {
    let params = new FormData();
    params.append("uid", uid);
    return this.httpClient.post(
      `${this.REST_API_SERVER}/getUserSettings`,
      params,
      this.headers
    );
  }

  public alterUserSettings(uid, tracker) {
    let params = new FormData();
    params.append("uid", uid);
    params.append("tracker", tracker);
    return this.httpClient.post(
      `${this.REST_API_SERVER}/AlterUserSettings`,
      params,
      this.headers
    );
  }

  public alterCartObs(uid, obsid) {
    let params = new FormData();
    params.append("uid", uid);
    params.append("obsid", obsid);
    return this.httpClient.post(
      `${this.REST_API_SERVER}/alterCartObs`,
      params,
      this.headers
    );
  }

  public delCartObs(uid, obsid) {
    let params = new FormData();
    params.append("uid", uid);
    params.append("obsid", obsid);
    return this.httpClient.post(
      `${this.REST_API_SERVER}/delCartObs`,
      params,
      this.headers
    );
  }

  /*Atlasbokningen*/
  public getBooking(ruta) {
    let params = new FormData();
    params.append("ruta", ruta);
    return this.httpClient.post(
      `${this.REST_API_SERVER}/getBooking`,
      params,
      this.headers
    );
  }

  public getBookings() {
    let params = new FormData();
    return this.httpClient.post(
      `${this.REST_API_SERVER}/getBookings`,
      params,
      this.headers
    );
  }

  public addBooking(ruta, namn, epost, mobil, kommentar, geojson, rutdata) {
    let params = new FormData();
    params.append("ruta", ruta);
    params.append("namn", namn);
    params.append("epost", epost);
    params.append("mobil", mobil);
    params.append("kommentar", kommentar);
    params.append("geojson", geojson);
    params.append("rutdata", rutdata);
    return this.httpClient.post(
      `${this.REST_API_SERVER}/addBooking`,
      params,
      this.headers
    );
  }

  public delBooking(ruta) {
    let params = new FormData();
    params.append("ruta", ruta);
    return this.httpClient.post(
      `${this.REST_API_SERVER}/delBooking`,
      params,
      this.headers
    );
  }

  public verBooking(ruta, ver) {
    let params = new FormData();
    params.append("ruta", ruta);
    params.append("ver", ver);
    return this.httpClient.post(
      `${this.REST_API_SERVER}/verBooking`,
      params,
      this.headers
    );
  }
}
