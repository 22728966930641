<div style="display: flex;flex-direction: row;">
  <div style="position: relative;width:40%">
    <div
      class="map"
      style="height: 100vh; width: 100%"
      id="map"
      leaflet
      [leafletOptions]="options"
      [leafletLayers]="markers"
      (leafletMapReady)="onMapReady($event)"
    ></div>
    <div class="bokningarLegend" fxFlex>
      <div>
        <div style="display: flex;flex-direction: row;">
          <div
            fxFlex="15px"
            class="bokningarLegendColor"
            style="background-color: rgb(159, 32, 231)"
          ></div>
          <div fxFlex class="bokningarLegendText">Bokad, ej verifierad</div>
        </div>
        <div style="display: flex;flex-direction: row;">
          <div
            fxFlex="15px"
            class="bokningarLegendColor"
            style="background-color: rgb(255, 154, 41)"
          ></div>
          <div fxFlex class="bokningarLegendText">Bokad, verifierad</div>
        </div>
      </div>
    </div>
  </div>
  <div style="color: white;width:60%">
      <div style="position: relative;width:100%;display: flex;flex-direction: row;justify-content: space-between;">
        <div style="width:60px;">
          <button
            mat-card-avatar
            mat-mini-fab
            color="primary"
            aria-label="Tillbaka till bokningssidan"
            routerLink="../atlas"
            style="background-color: #277e32; color: #fff; margin: 2px"
          >
            <mat-icon>arrow_back</mat-icon>
          </button>
        </div>
        <div>
            Bokade inventeringsrutor i Atlasprojektet<br>
            Klicka på kartan för att välja ruta
        </div>
        <div>
          <img
          src="../../assets/images/sbflogga.jpg"
          style="position: absolute; right: 0px; height: 60px"
        />
        </div>
      </div>
        <div
          *ngIf="bokaRutaInfo != null" style="margin:10px;"
        >
          <div>
                Ruta: #{{ bokaRutaInfo["ruta"] }}<br>
                Bokningsdatum: {{ bokaRutaInfo["added"] }}
          </div>
          <div>
            <div *ngIf="bokaRutaInfo != null">
              <b>Provins:</b><br />
              <h3>{{ metadata.properties.province }}</h3>
              <b>Status: {{ metadata.properties.Bristkategori }}</b
              ><br />

              <b>Antal noterade arter:</b> {{ metadata.properties.artantal
              }}<br />
              <b>Mål antal arter:</b> {{ metadata.properties.krav }}<br />
              <hr />
              <h3><b>Bokare:</b></h3>
              {{ bokaRutaInfo["namn"] }}<br />
              {{ bokaRutaInfo["epost"] }}<br />
              {{ bokaRutaInfo["mobil"] }}<br />
              <span *ngIf="bokaRutaInfo['kommentar'] != null">
                Kommentar från bokaren:<br />
                <i>{{ bokaRutaInfo["kommentar"] }}</i>
              </span>
            </div>
          </div>
          <div style="margin-top:50px;">
            <div *ngIf="bokaRutaInfo != null">
              <button mat-raised-button style="background-color: red;color:white;margin:10px;" (click)="delBokning()">
                Ta bort bokningen
              </button>
              <button
                *ngIf="bokaRutaInfo['verifierad'] == 0"
                mat-raised-button
                style="background-color: green;color:white;margin:10px;"
                (click)="verBokning(1)"
              >
                Verifiera bokningen <upper>*</upper>
              </button>
              <button
                *ngIf="bokaRutaInfo['verifierad'] == 1"
                mat-raised-button
                style="background-color: yellow;color:black;margin:10px;"
                (click)="verBokning(0)"
              >
                Ta bort verifieringen <upper>*</upper>
              </button>
              <br />
              <p>
                <i style="font-size: small">
                  * Verifiera en bokning betyder att den har setts och projektet
                  vet om den.
                </i>
              </p>
            </div>
          </div>
        </div>
  </div>
</div>
