import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AppComponent } from "./app.component";
import { AtlasComponent } from "./atlas/atlas.component";

import { AppRoutingModule } from "./app-routing.module";

import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import {
  FontAwesomeModule,
  FaIconComponent,
} from "@fortawesome/angular-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TokenStorageService } from "./_services/token-storage.service";
import { AuthInterceptor } from "./_helpers/auth.interceptor";
import { MatSortModule } from "@angular/material/sort";
import { CookieModule } from "ngx-cookie";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { BokningarComponent } from "./bokningar/bokningar.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { LoginComponent } from "./login/login.component";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';

@NgModule({
  imports: [
    ReactiveFormsModule,
    MatSortModule,
    BrowserModule,
    FormsModule,
    FontAwesomeModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    LeafletModule,
    CookieModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    BrowserAnimationsModule,
    MatButtonModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSnackBarModule,
  ],
  providers: [
    TokenStorageService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  declarations: [
    AppComponent,
    AtlasComponent,
    BokningarComponent,
    NavbarComponent,
    LoginComponent,
  ],
  bootstrap: [AppComponent],
  entryComponents: [FaIconComponent],
  
})
export class AppModule {
  constructor() {
    library.add(fas);
  }
}

